import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

import AdminLogin from './views/pages/AdminLogin/AdminLogin'
import { getFirestore } from 'firebase/firestore'
import './i18Next'
import './App.css'
import './scss/style.scss'
// import LandingPage from './views/pages/LandingPage/landingPage'

window.Buffer = window.Buffer || require('buffer').Buffer

const firebaseConfig = {
  projectId: 'hopa-pet-fb3c9',
  appId: process.env.REACT_APP_ID,
  messagingSenderId: '545454066703',
  authDomain: 'hopa-pet-fb3c9.firebaseapp.com',
  storageBucket: 'hopa-pet-fb3c9.appspot.com',
  apiKey: 'AIzaSyDsCuFsFKFeLpkfMB8nmLZ4pZn_la1N50k',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// export const timestamp = firebase.firestore.FieldValue.serverTimestamp
export const app = initializeApp(firebaseConfig)
export const authentication = getAuth(app)
export const storage = getStorage(app)
export const firestore = getFirestore(app)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/OnBoarding'))
// const AdminLogin = React.lazy(() => import('./views/pages/AdminLogin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const SplashScreen = React.lazy(() => import('./views/pages/splashScreen/Success'))
const AdminPanel = React.lazy(() => import('./views/pages/adminPanel/AdminPanel'))
const LandingPage = React.lazy(() => import('./views/pages/LandingPage/landingPage'))

const App = () => {
  const subdomain = window?.location?.hostname?.split('.')[0]
  const isUser = subdomain && subdomain !== 'localhost' && subdomain !== 'admin' ? true : false

  // render() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route exact path="/" name="Admin Login" element={<AdminLogin />} /> */}
          {isUser ? (
            <>
              <Route exact path="/" name="Landing Page" element={<LandingPage />} />
              <Route exact path="/onboarding/:id" name="Login Page" element={<Login />} />
              <Route exact path="/register/:id" name="Register Page" element={<Register />} />
              <Route exact path="/success" name="Splash Screen" element={<SplashScreen />} />
            </>
          ) : (
            <>
              <Route exact path="/" name="Admin Panel" element={<AdminLogin />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
  // }
}

export default App
