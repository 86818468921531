import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DogImage from 'src/assets/images/DogloginCardimage.png'
import Dogicon from 'src/assets/images/dogLogo.svg'
import HopaIcon from 'src/assets/images/hopaIcon.svg'
import loginIcon from 'src/assets/images/loginIcon.svg'
import WhatsappIcon from 'src/assets/images/whatsappIcon.png'
import { useFormikHook } from 'src/hooks/useFormikHook'
import * as yup from 'yup'

const AdminLogin = () => {
  const navigate = useNavigate()

  const [username, setusername] = useState(false)
  const [password, setpassword] = useState(false)
  const email = 'mohsinzia@gmail44.com'
  const passw = '123@123'

  const user = () => {
    setusername(true)
    setpassword(false)
  }
  const pass = () => {
    setusername(false)
    setpassword(true)
  }

  const loginbutton = (email, password) => {
    const auth = getAuth()
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user
    //     console.log('user res', user)
    //     // ...
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code
    //     const errorMessage = error.message
    //     console.log('error massege ', errorMessage)
    //     // ..
    //   })
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        if (user) {
          localStorage.setItem('userId', user.uid)
          localStorage.setItem('accessToken', user.accessToken)
          navigate(`/dashboard`)
        } else {
          return
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error massege ', errorMessage)
        alert(errorCode)
        // ..
      })
  }

  const validationSchema = yup.object().shape({
    email: yup.string().required().label('dogName'),
    password: yup.string().required().label('OwnerName'),
  })

  const initialValues = {
    email: '',
    password: '',
  }
  const submit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    }
    loginbutton(data.email, data.password)
  }

  const { handleChange, handleSubmit, setFieldTouched, errors, touched, values } = useFormikHook(
    submit,
    validationSchema,
    initialValues,
  )

  const submitdata = (data) => {}
  return (
    <div>
      <div className="row">
        <div className="col-12  loginbackground vh-100 d-flex align-items-center justify-content-center ">
          <div
            className="d-flex  logincard shadow-2xl bg-white "
            style={{ height: '80%', justifyContent: 'center' }}
          >
            <div className="row" style={{ height: '100%' }}>
              <div className="col-lg-5 dogCol h-100 overflow-auto loginDogImageContainer">
                <img className="h-100 w-100" style={{ objectFit: 'cover' }} src={DogImage} alt="" />
              </div>
              <div className="col-lg-7 col-12" style={{ paddingTop: '1rem' }}>
                <div className="w-100 d-flex justify-content-center">
                  <div className="d-grid">
                    <div className="w-100 d-flex justify-content-center ">
                      <img style={{ height: '3rem', width: '3rem' }} src={Dogicon} alt="" />
                    </div>
                    <img
                      style={{ height: '5rem', width: '5rem', marginTop: '-1rem' }}
                      src={HopaIcon}
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <h3 className="opacity-75" style={{ fontWeight: '600' }}>
                    Welcome To Admin Panel
                  </h3>
                </div>
                <div className="w-100 d-flex mt-10 justify-content-center paraDiv align-content-center">
                  <p
                    className="font-weight-light"
                    style={{ fontSize: '0.8rem', fontWeight: '300' }}
                  >
                    Manage and operate the tags, pets, users and functionality of entire data of the
                    product <span>Hopa-Pet</span>
                  </p>
                </div>
                <div className="w-100 d-block">
                  <form onSubmit={submitdata}>
                    <div className="Logininputs">
                      <div className="w-100 position-relative">
                        <input
                          placeholder="Enter user name"
                          className="w-100 "
                          type="text"
                          // onClick={user}
                          style={{ fontSize: '0.9rem', fontWeight: '200' }}
                          onChange={handleChange('email')}
                          onBlur={() => {
                            setFieldTouched('email')
                          }}
                        />
                        {username && (
                          <div
                            className=" w-10 bg-black absolute left-0 top-0 position-absolute"
                            style={{ width: '0.3rem', height: '100%' }}
                          ></div>
                        )}
                      </div>
                      <div className="w-100 position-relative">
                        <input
                          style={{ fontSize: '0.9rem', fontWeight: '200' }}
                          placeholder="Enter password"
                          className="w-100"
                          type="password"
                          // onClick={pass}
                          onChange={handleChange('password')}
                          onBlur={() => {
                            setFieldTouched('password')
                          }}
                        />
                        {password && (
                          <div
                            className=" w-10 bg-black absolute left-0 top-0 position-absolute"
                            style={{ width: '0.3rem', height: '100%' }}
                          ></div>
                        )}
                      </div>

                      <div
                        className="w-100 d-flex justify-content-end  loginOptionDiv  "
                        style={{ cursor: 'pointer' }}
                        onClick={() => submit(values)}
                        type="submit"
                      >
                        <h3>LOGIN</h3> <img src={loginIcon} alt="" />
                      </div>
                    </div>
                  </form>
                </div>

                <div className="w-100  d-block">
                  <div className="LoginHavaccount">
                    <a href=" https://wa.me/972549666777" target="_blank" rel="noreferrer">
                      <h3>Dont have account? Contact us</h3>
                    </a>
                    <a
                      href=" https://wa.me/972549666777"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginTop: '-0.8rem' }}
                    >
                      <img style={{ height: '1rem', width: '1rem' }} src={WhatsappIcon} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
